(<template>
  <footer class="nav-footer">
    <div class="nav-footer__wrapper">
      <p class="nav-footer__copyright">{{ copyright }} {{ address }}</p>
      <locale-select class="nav-footer__lang-selector" />
    </div>
  </footer>
</template>)

<script>
  export default {
    computed: {
      copyright() { return this.$getString('footer', 'copyright'); },
      address() { return this.$getString('footer', 'address'); }
    }
  };
</script>

<style>
  .nav-footer {
    display: flex;
    width: 100%;
    height: 60px;
    min-height: 60px;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 34, 102, 0.1), 0 1px 3px 0 rgba(0, 34, 102, 0.2);
  }

  .nav-footer__wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 40px;
  }

  .nav-footer__copyright {
    margin: 0 15px;
    color: #737373;
  }

  .nav-footer__lang-selector {
    margin-left: auto;
    text-align: left;
  }

  .nav-footer__lang-selector .sk-select {
    width: 100px;
    border: none;
  }

  .nav-footer__lang-selector .sk-select__list {
    top: auto;
    bottom: 100%;
    color: #000;
    font-size: 12px;
  }

  @media (max-width: 767px) {
    .nav-footer__wrapper {
      padding: 0 10px;
    }

    .nav-footer__copyright {
      margin: 0;
      padding-right: 5px;
      font-size: 10px;
      text-align: left;
    }
  }
</style>
