(<template>
  <header :class="{'auth-header--wizard': loggedInUser}"
          class="auth-header">
    <router-link :to="$makeRoute({name: 'Landing'})"
                 class="auth-header__logo" />
    <button v-if="loggedInUser"
            class="sk-btn sk-btn--white auth-header__logout-btn"
            @click="logOut">{{ $gettext('Log out') }}</button>
  </header>
</template>)

<script>
  export default {
    computed: {
      loggedInUser() { return this.$store.state.TTAuthStore.loggedIn; }
    },
    methods: {
      logOut() {
        this.$store.dispatch('TTAuthStore/logOut').then(() => {
          this.$goToExternalLoginPage();
        });
      }
    }
  };
</script>

<style scoped>
  .auth-header {
    position: relative;
    z-index: 1;
    display: flex;
    flex-shrink: 0;
    width: 100%;
    height: 60px;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 34, 102, 0.1), 0 1px 2px 0 rgba(0, 34, 102, 0.2);
  }

  .auth-header--wizard {
    align-items: center;
    justify-content: space-between;
    padding-right: 15px;
  }

  .auth-header__logo {
    display: block;
    width: 60px;
    height: 60px;
    background-image: url(~@assets/imgs/logos/single_logo_red.svg);
    background-image: var(--image-single-logo);
    background-position: 50% 50%;
    background-size: 31px auto;
    background-repeat: no-repeat;
  }

  .auth-header__logout-btn {
    display: inline-block;
    width: auto;
    padding: 0 15px;
  }

  @media (max-width: 767px) {
    .auth-header {
      display: none;
    }

    .auth-header--wizard {
      display: flex;
    }
  }
</style>
