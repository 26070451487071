(<template>
  <div class="wizard">
    <auth-header />
    <div class="wizard__content-wrapper">
      <div class="wizard__content-cont">
        <router-view />
      </div>
    </div>
    <auth-footer />
  </div>
</template>)

<script>
  import AuthHeader from '@/components/auth_components/AuthHeader';
  import AuthFooter from '@/components/auth_components/AuthFooter';

  export default {
    asyncData({store}) {
      let promisesArr = '';
      if (!store.state.selectsData.language || !store.state.selectsData.language.length) {
        promisesArr ? promisesArr.push(store.dispatch('fetchSelectList', 'language')) : promisesArr = [store.dispatch('fetchSelectList', 'language')];
      }
      if (!store.state.selectsData.qualification || !store.state.selectsData.qualification.length) {
        promisesArr ? promisesArr.push(store.dispatch('fetchSelectList', 'qualification')) : promisesArr = [store.dispatch('fetchSelectList', 'qualification')];
      }

      return promisesArr ? Promise.all(promisesArr) : '';
    },
    components: {
      'auth-header': AuthHeader,
      'auth-footer': AuthFooter
    }
  };
</script>

<style scoped>
  .wizard {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .wizard__content-wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: center;
    width: 100%;
    padding: 60px 0;
    background-color: #f2f2f2;
  }

  .wizard__content-cont {
    display: block;
    align-self: flex-start;
    width: 100%;
    max-width: 850px;
    background-color: #fff;
  }

  @media (max-width: 767px) {
    .wizard__content-wrapper {
      padding: 0;
    }

    .wizard__content-cont {
      align-self: stretch;
      max-width: initial;
    }
  }
</style>
